<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="oldPass"
        center
        clearable
        type="password"
        label="原密码"
        maxlength="15"
        placeholder="请输入原密码"
        :rules="[
          { required: true, message: '' },
          { validator: asyncValidator, message: '请输入原密码' },
        ]"
      >
      </van-field>
      <van-field
        v-model="newPass"
        center
        clearable
        type="password"
        label="新密码"
        maxlength="15"
        placeholder="请输入新密码"
        :rules="[
          { required: true, message: '' },
          { validator: asyncValidator, message: '请输入新密码' },
        ]"
      >
      </van-field>

      <div style="margin: 25px">
        <van-button round block type="info" native-type="submit">
          确定修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      oldPass: "",
      newPass: "",
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.http.post("/user/pwd", {"userId":localStorage.getItem("id"),"oldPwd":this.oldPass
      ,"newPwd":this.newPass}).then((res) => {
        if(res.data.code == 200){
           Toast.success('修改成功');
           this.$router.go(-1)
        }
      });
    },
    asyncValidator(val) {
      return val!=null
    },
  },
};
</script>

<style>
</style>